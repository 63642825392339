import React from 'react';
import { IWeatherV2Props } from './types';
import { functions, useApp } from '@wap-client/core';
import Anchor from '@/components/base/anchor';
import { Container } from '@/components/base/gridview';
import Icon from '@/components/base/icon';
import WeatherV2Card from '../weather-v2-card';
import useDeviceType from '@/hooks/useDeviceType';

const WeatherV2: React.FC<IWeatherV2Props> = ({ cityNameList, moreLink }) => {
  const app = useApp();
  const deviceType = useDeviceType();

  const renderCityList = (): Array<JSX.Element> | null => {
    if (cityNameList && cityNameList?.length > 0) {
      const cities = cityNameList.slice(0, 4)?.filter((city) => city?.title);

      return cities.map((city, index) => (
        <WeatherV2Card cityName={city.title} key={`weather-card-${index}`} />
      ));
    }

    return null;
  };

  const renderMoreButton = () => {
    if (!moreLink?.href) return null;

    return (
      <Anchor
        {...moreLink}
        className={functions.classnames(
          'weather-more-link',
          deviceType === 'mobile' && 'mobile'
        )}
      >
        {app.settings.translations['more-information-weather']}
        <Icon name="icon-arrow-right-outline" />
      </Anchor>
    );
  };

  return (
    <div className="weather-v2">
      <Container>
        <div className="head">
          <h3>{app.settings.translations['weather']}</h3>
          {deviceType !== 'mobile' && renderMoreButton()}
        </div>
        <div className="city-list">{renderCityList()}</div>
        {deviceType === 'mobile' && renderMoreButton()}
      </Container>
    </div>
  );
};

export default WeatherV2;
